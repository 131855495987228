<template>
  <div class="avatar">
    <ion-avatar class="avatar-image" v-if="photoUrl">
      <ion-img :src="photoUrl" alt="avatar" />
      <div class="avatar-border">&nbsp;</div>
    </ion-avatar>
    <ion-text color="primary" @click="triggerUpload">{{ $t('change_profile_photo') }}</ion-text>
    <ion-input
      placeholder=""
      type="file"
      accept="image/*"
      capture="camera"
      @ionInput="onPhotoChanged"
      ref="photoInput"
      v-model="photoInput"
      class="d-none"
    >
    </ion-input>
    <ion-modal
      :is-open="isOpenModalCropRef"
      css-class="modal-upload-image"
      @didDismiss="setOpenModalCrop(false)"
    >
      <crop-box :src="src" @close-modal="closeCropBox" @crop-image="onCrop" />
    </ion-modal>
  </div>
</template>

<script>
import { IMAGE_PATH } from '@/modules/b2b/constants/index';
import { fileToBase64 } from '@/modules/b2b/services/libs/image';
import CropBox from '@/modules/shared/views/CropBox';
import { getImage } from '@/utils/';
import { imageOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'UpdateAvatar',
  components: {
    // user defined component
    CropBox
  },
  props: {
    image: {
      required: true
    }
  },
  data() {
    return {
      photoUrl: '',
      photoInput: null
    };
  },

  setup() {
    // modal crop
    const isOpenModalCropRef = ref(false);
    const setOpenModalCrop = (state) => (isOpenModalCropRef.value = state);

    return {
      imageOutline,
      isOpenModalCropRef,
      setOpenModalCrop
    };
  },
  async mounted() {
    this.photoUrl = await getImage(this.image, IMAGE_PATH.USER);
    this.$nextTick(async () => {
      this.photoUrl = await getImage(this.image, IMAGE_PATH.USER);
    });
  },
  methods: {
    async triggerUpload() {
      const ionInputEle = this.$refs.photoInput.$el;
      const inputEle = await ionInputEle.getInputElement();
      inputEle.click();
    },
    async onPhotoChanged(event) {
      const files = event.target.querySelector('input').files;
      if (!files.length) {
        return;
      }

      if (!files[0].type.includes('image')) {
        return;
      }

      this.src = await fileToBase64(files[0]);
      this.setOpenModalCrop(true);
    },
    onCrop(cropImg) {
      this.photoInput = null;
      this.photoUrl = cropImg;
      this.$emit('crop-image', cropImg);
      this.setOpenModalCrop(false);
    },
    closeCropBox() {
      this.photoInput = null;
      this.setOpenModalCrop(false);
    }
  }
});
</script>

<style lang="scss" scoped>
.avatar {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  & > ion-text {
    margin-top: 16px;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
  }
}

.avatar-image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  position: relative;
}

.avatar-border {
  border-radius: 50%;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  position: absolute;
}
.btn-upload {
  position: absolute;
  bottom: -5px;
  right: 0px;
  font-size: 12px;
}
.btn-upload::part(native) {
  padding: 0px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
</style>

<style>
.modal-crop.custom-modal .modal-wrapper {
  margin: 24px 32px;
  height: 390px;
  width: 390px;
  min-height: 390px;
  min-width: 390px;
  border-radius: 12px;
}
.modal-crop.custom-modal .modal-shadow {
  display: none;
}
</style>
